import { routes, Get_Route } from "routes";
import { Link, useRoutes, useNavigate, useLocation } from "react-router-dom";
import "./style.css";
const Home = () => {
  const navigate = useNavigate();
  return (
    <div className="home-container">
      <h1 className="card-title">Greenwich International - Home</h1>
      <div className="nav-links-container">
        {routes.map((route, index) =>
          route.isVisible ? (
            <div
              onClick={() => navigate(route.path)}
              key={index}
              className={"nav-links"}
            >
              {route.name}
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export default Home;
