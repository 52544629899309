import axios from "axios";
import toast from "react-hot-toast";


const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use((config) => {
  const authToken = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);

  if (typeof guestToken !== undefined) {
    config.headers["Authorization"] = `Bearer ${authToken}`;
  }

  return config;
});

api.interceptors.response.use(
  response => {

    if (response.status == 401) {
      window.location = '/login';
      return Promise.reject(response);
    }

    if (response.status >= 400) {
      return Promise.reject(response);
    }

    return Promise.resolve(response);
  }
);

api.getUrl = () => {
  return new URL(api.getUri());
}

api.setUrlEndpoint = endpoint => {
  return new URL(api.getUri() + endpoint);
}

export default api;
