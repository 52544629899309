import api from "./api";
class CredentialsRequests {
  #url = process.env.REACT_APP_API_URL + "/integrations/credentials";

  async listCredentialsByIntegrationID(integrationId) {
    const resp = await api.get(
      this.#url + "/list?integration_id=" + integrationId
    );
    return resp.data;
  }

  async getCredentialByID(credentialID) {
    const resp = await api.get(
      this.#url + "/details?credential_id=" + credentialID
    );
    return resp.data;
  }

  async getExpensesByCredentialID(credentialID) {
    const resp = await api.get(
      this.#url + "/expenses?credential_id=" + credentialID
    );
    return resp.data;
  }

  async getAll() {
    const resp = await api.get(this.#url);
    return resp.data;
  }
  async create(req) {
    const resp = await api.post(this.#url + "/add", {
      integration_id: req.integration_id,
      name: req.name,
      api_user: req.api_user,
      api_password: req.api_password,
    });
    return resp;
  }

  async update(req) {
    const resp = await api.patch(this.#url + "/update", {
      credential_id: req.id,
      integration_id: req.integration_id,
      name: req.name,
      api_user: req.api_user,
      api_password: req.api_password,
      // client_id: 1222,
    });
    return resp;
  }
  /**
   * @param credentialID credential_id - 1º
   * @param integrationID integration_id - 2º
   */
  async remove(credentialID, integrationID) {
    const resp = await api.delete(this.#url + "/delete", {
      params: {
        integration_id: integrationID,
        credential_id: credentialID,
      },
    });

    return resp;
  }

  
  async addExpense(data) {
    const resp = await api.post(this.#url + "/expenses/add", data);
    return resp;
  }

  async updateExpense(data) {
    const resp = await api.patch(this.#url + "/expenses/update", data);
    return resp;
  }

  async deleteExpense(data) {
    const resp = await api.delete(this.#url + "/expenses/delete", {params: data});
    return resp;
  }
  
}
export default new CredentialsRequests();
