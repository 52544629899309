import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  FormFeedback,
  FormGroup,
  FormText,
  Form,
  Input,
  Label,
  Button,
  ButtonGroup,
  Col,
  Row,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
} from "reactstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import credentialsRequests from "requests/credentialsRequests";
import ReturnButton from "components/ReturnButton";
import { BxShow } from "components/Icon/show";

import "./style.css";

const CreateCredential = () => {
  const { state } = useLocation();
  const [showPass, setShowPass] = useState(false);
  const [integration, setIntegration] = useState({});
  const [credential, setCredential] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setIntegration(state.integration);
  }, [integration]);

  const saveHandle = () => {
    toast
      .promise(
        credentialsRequests.create({
          integration_id: integration.id,
          name: credential.name,
          api_user: credential.api_user,
          api_password: credential.api_password,
        }),
        {
          loading: "Salvando usuário...",
          success: "Usuário adicionado com success!",
          error: (err) => "Erro - " + err.response.data.error_message,
        }
      )
      .then((e) => {
        navigate(`/integrations/access?integration_id=${integration.id}`, {
          state: { integration },
        });
      })
      .catch((err) => {
        const error = err.message;
        console.log(err);
      });
  };
  const cancelHandle = () => {
    navigate(-1, { state: { integration } });
  };
  return (
    <div className="credential-create-container">
      <Container>
        <Card body>
          <div className="credential-create-body">
            <CardHeader
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0",
                padding: "0",
              }}
            >
              <p
                style={{
                  margin: "0",
                  padding: "0.5rem",
                  display: "flex",
                  height: "100%",
                  alignItems: "center",
                  fontSize: "15px",
                  gap: "0.5rem",
                }}
              >
                <b style={{ fontSize: "15px" }}>{`${integration?.name}:`}</b>
                Criar Acesso
              </p>
            </CardHeader>
            <CardBody
              style={{
                margin: "0 !important",
                padding: "0 !important",
                overflow: "overlay !important",
              }}
            >
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  saveHandle();
                }}
              >
                {/* credential name */}
                <FormGroup style={{ margin: "1rem 0 0 0 " }}>
                  <Label for="credential.name">
                    <b>Nome:</b>
                  </Label>
                  <Input
                    defaultValue={credential?.name}
                    onChange={(e) => {
                      setCredential({
                        ...credential,
                        name: e.target.value,
                      });
                    }}
                  />
                </FormGroup>
                {/* credential user */}
                <FormGroup>
                  <Label for="api_user">
                    <b>Usuário:</b>
                  </Label>
                  <Input
                    type="text"
                    defaultValue={credential?.api_user}
                    onChange={(e) => {
                      setCredential({
                        ...credential,
                        api_user: e.target.value,
                      });
                    }}
                  />
                </FormGroup>

                {/* password */}
                <FormGroup>
                  <Label for="credential.password">
                    <b>Senha:</b>
                  </Label>

                  <div className="show-button-container">
                    <Input
                      type={showPass ? "text" : "password"}
                      defaultValue={credential?.api_password}
                      onChange={(e) => {
                        setCredential({
                          ...credential,
                          api_password: e.target.value,
                        });
                      }}
                    />
                    <BxShow
                      show={showPass}
                      onClick={() => {
                        setShowPass(!showPass);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  style={{
                    display: "grid",
                    margin: "  2rem 0 0 0",
                    gridGap: "1rem",
                    gridTemplateColumns: "1FR 1FR 1fr 1FR",
                  }}
                >
                  <Button
                    type="submit"
                    id="credential-save-button"
                    style={{
                      color: "white",
                      background: "var(--COLOR_1)",
                      gridColumn: 2,
                    }}
                  >
                    Salvar
                  </Button>

                  <Button
                    type="reset"
                    onClick={() => cancelHandle()}
                    id="credential-cancel-button"
                    style={{ gridColumn: 3 }}
                  >
                    Cancelar
                  </Button>
                </FormGroup>
              </Form>
            </CardBody>
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default CreateCredential;
