import "assets/styles/app.css";
import ReturnButton from "components/ReturnButton";
import { useLocation, useNavigate } from "react-router-dom";

const NotFounnd = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  return (
    <div className="not-found-container">
      <span className="card-title">
        <h2>Not Found</h2>
        <ReturnButton step={2} className="return-button" />
      </span>
      <h3>Erro</h3>
      <button className="go-home-button" onClick={() => navigate("/")}>
        Home
      </button>
    </div>
  );
};

export default NotFounnd;
