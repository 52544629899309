import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { routes } from "routes";
import { BrowserRouter, Routes as Switch, Route } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import Login from "pages/Auth/Login";

import Side from "components/Side";
import Main from "components/Main";

import Web from "Web";

import authRequests from "requests/authRequests";

import "./assets/styles/app.css";
import 'bootstrap/dist/css/bootstrap.min.css';

const loading = (
  	<div className="pt-3 text-center">
    	<div className="sk-spinner sk-spinner-pulse"></div>
  	</div>
);

function App() {


  const [ loggedIn , setLoggedIn ] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {

	authRequests.validateSession()
	.then(response => {
		setLoggedIn(true);
	} , error => {
		setLoggedIn(false);
		navigate({ pathname: "/login" });
	})

  },[])

  return (
      <div className={"page-container"}>
	  		<Toaster />
			{loggedIn === null && loading}
			<Switch>
				<Route path={'/login'} element={<Login />} />
				{loggedIn === true && <Route path={'*'} element={<Web />} />}				
			</Switch>			
      </div>
  );
}

export default App;
