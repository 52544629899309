import api from "./api";

class AuthRequests {

    async login(email , pass){
        return await api.post(`/auth/login` , {email: email , password: pass});
    }

    async validateSession(){
        return await api.get(`/auth/session`);
    }

}

export default new AuthRequests();