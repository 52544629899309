import { useNavigate } from "react-router-dom";

const ReturnButton = (props) => {
  const navigate = useNavigate();
  const clickHandle = () => {
    navigate(-1 * (props.step ?? 1));
  };
  return (
    <div className={props.className} onClick={() => clickHandle()}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.size}
        height={props.size}
        viewBox="0 0 512 512"
      >
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={52}
          d="M244 400L100 256l144-144M120 256h292"
        />
      </svg>
    </div>
  );
};

export default ReturnButton;
