import Header from "./Header";
import { Get_Route } from "routes";
import "../assets/styles/app.css";

const Main = ({ children }) => {
  return (
    <>
      <div className="main-container">
        <Header/>
        {children}
      </div>
    </>
  );
};

export default Main;
