import moment from 'moment';

const dateHelper = {};

 dateHelper.toDDMMYYYY = (date) => {
    return moment(date).format('DD/MM/YYYY');
}

 dateHelper.toYYYYMMDD = (date) => {
    return moment(date).format('YYYY-MM-DD');
}

dateHelper.formatDateWithHours = (date) => {
    return moment.utc(date).format('DD/MM/YYYY HH:mm')
}

dateHelper.fromFormat = (datevalue, format = 'YYYY-MM-DD hh:mm:ss') => {
    return moment(datevalue, format);
}

dateHelper.changeFormat = (datevalue, fmtsrc = 'YYYY-MM-DD hh:mm:ss', fmtdst = 'DD/MM/YYYY') => {
    return dateHelper.fromFormat(datevalue, fmtsrc).format(fmtdst)
}

dateHelper.tryformat = (date, format = 'DD/MM/YYYY') => {
    let regexp = [
        { reg: '([0-9]{4})' }
    ]
    return regexp;
}

export default dateHelper;