import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormFeedback,
  FormGroup,
  FormText,
  Form,
  Input,
  Label,
  Button,
  ButtonGroup,
  Col,
  Row,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Container,
} from "reactstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import dateHelper from "helpers/dateHelper";
import credentialsRequests from "requests/credentialsRequests";
import ReturnButton from "components/ReturnButton";
import { BxShow } from "components/Icon/show";

import "./style.css";

const EditCredential = () => {
  const { state } = useLocation();
  const [isValid, setValid] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [credential, setCredential] = useState({});
  const [integration, setIntegration] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setCredential(state.credential);
    setIntegration(state.integration);
  }, []);

  const saveHandle = () => {
    toast
      .promise(credentialsRequests.update({ ...credential }), {
        loading: "Salvando alterações...",
        success: "Usuário atuallizado!",
        error: (err) => "Erro - " + err.response.data.error_message,
      })
      .then((el) => {
        navigate(
          `/integrations/access?integration_id=${credential.integration.id}`,
          {
            state: { el, integration },
          }
        );
      })
      .catch((err) => {
        navigate(-1, { state: { integration } });
        console.error(err);
      });
  };

  const cancelHandle = () => {
    navigate(-1, { state: { credential } });
  };

  return (
    <div className="credential-edit-container">
      <Container>
        <Card body>
          <div className="credential-edit-body">
            <CardHeader
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0",
                padding: "0",
              }}
            >
              <p
                style={{
                  margin: "0",
                  padding: "0.5rem",
                  display: "flex",
                  height: "100%",
                  alignItems: "center",
                  fontSize: "15px",
                  gap: "0.5rem",
                }}
              >
                <b style={{ fontSize: "15px" }}>Editar Acesso: </b>
                {state.credential.name} • {integration.name}
              </p>
            </CardHeader>
            <CardBody
              style={{
                margin: "0 !important",
                padding: "0 !important",
                overflow: "overlay !important",
              }}
            >
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  saveHandle();
                }}
              >
                {/* credential name */}
                <FormGroup style={{ margin: "1rem 0 0 0 " }}>
                  <Label for="credential.name">
                    <b>Nome:</b>
                  </Label>
                  <Input
                    defaultValue={credential?.name}
                    onChange={(e) => {
                      setCredential({
                        ...credential,
                        name: e.target.value,
                      });
                    }}
                  />
                </FormGroup>
                {/* credential user */}
                <FormGroup>
                  <Label for="api_user">
                    <b>Usuário:</b>
                  </Label>
                  <Input
                    type="text"
                    defaultValue={credential?.api_user}
                    onChange={(e) => {
                      setCredential({
                        ...credential,
                        api_user: e.target.value,
                      });
                    }}
                  />
                </FormGroup>

                {/* password */}
                <FormGroup>
                  <Label for="credential.password">
                    <b>Senha:</b>
                  </Label>

                  <div className="show-button-container">
                    <Input
                      type={showPass ? "text" : "password"}
                      defaultValue={credential?.api_password}
                      onChange={(e) => {
                        setCredential({
                          ...credential,
                          api_password: e.target.value,
                        });
                      }}
                    />
                    <BxShow
                      show={showPass}
                      onClick={() => {
                        setShowPass(!showPass);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup
                  style={{
                    display: "grid",
                    margin: "  2rem 0 0 0",
                    gridGap: "1rem",
                    gridTemplateColumns: "1FR 1FR 1fr 1FR",
                  }}
                >
                  <Button
                    type="submit"
                    id="credential-save-button"
                    style={{
                      color: "white",
                      background: "var(--COLOR_1)",
                      gridColumn: 2,
                    }}
                  >
                    Salvar
                  </Button>

                  <Button
                    type="reset"
                    onClick={() => cancelHandle()}
                    id="credential-cancel-button"
                    style={{ gridColumn: 3 }}
                  >
                    Cancelar
                  </Button>
                </FormGroup>
              </Form>
            </CardBody>
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default EditCredential;
