import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { routes } from "routes";
import { BrowserRouter, Routes as Switch, Route } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import Login from "pages/Auth/Login";

import Side from "components/Side";
import Main from "components/Main";

import authRequests from "requests/authRequests";

import "./assets/styles/app.css";
import 'bootstrap/dist/css/bootstrap.min.css';

const loading = (
  	<div className="pt-3 text-center">
    	<div className="sk-spinner sk-spinner-pulse"></div>
  	</div>
);

function Web() {


  return (
      <>
	  	<Side />
		<Main>				
			<Switch>
				{routes.map((route, index) => {
				return (
					<Route
						key={index}
						path={route.path}
						element={route.element}
					/>
				);
				})}
			</Switch>
		</Main>
      </>
  );
}

export default Web;
