import "./index.css";

import toast from "react-hot-toast";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Table,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";

import brokerRequest from "requests/brokerRequests";
import dateHelper from "helpers/dateHelper";

const Shipments = () => {
  const [shipments, setShipments] = useState([]);
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const handleShipmentDetails = (shipmentId) => {
    navigate({
      pathname: "/broker/shipment/details",
      search: `?shipment_id=${shipmentId}`,
    });
  };

  const handleFindShipments = (e) => {
    e.preventDefault();

    toast
      .promise(brokerRequest.findShipments(query), {
        loading: "Carregando processos...",
        success: "Sucesso",
        error: "Não foi possível processar os dados",
      })
      .then((res) => setShipments(res.data));
  };

  return (
    <div
      style={{
        flexDirection: "column",
        padding: "15px",
        height: "100% ",
        overflow: "hidden",
      }}
    >
      <Card style={{ height: "100px" }}>
        <CardBody>
          <Form onSubmit={handleFindShipments}>
            <FormGroup>
              <Row className="align-items-center">
                <Col sm="10">
                  <Label for="query-param">Buscar processos</Label>
                  <Input
                    id="query-param"
                    type="text"
                    placeholder="Digite aqui a referência do processo"
                    onChange={(e) => setQuery(e.target.value)}
                  />
                </Col>
                <Col sm="2" className="mt-4">
                  <Button color="primary">Pesquisar</Button>
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
      <Card
        style={{
          marginTop: "15px",
          height: "calc(100% - 100px - 1rem)",
          overflow: "hidden",
        }}
      >
        <CardHeader></CardHeader>
        <div style={{ overflowY: "overlay" }}>
          <CardBody>
            <Table hover striped size="sm">
              <thead>
                <tr
                  style={{
                    position: "sticky",
                    top: "10",
                    background: "white",
                    zIndex: "100",
                  }}
                >
                  <th>REFERÊNCIA PROCESSO</th>
                  <th>REFERÊNCIA CLIENTE</th>
                  <th>CLIENTE</th>
                  <th>DATA DE ABERTURA</th>
                  <th></th>
                </tr>
              </thead>
              <tbody style={{}}>
                {shipments?.map((shipment) => {
                  return (
                    <tr key={shipment?.IDPROCESSO}>
                      <td>{shipment?.NRPROCESSO}</td>
                      <td>{shipment?.NRREFAUXILIAR}</td>
                      <td>
                        {shipment?.cliente?.NMPESSOA} (
                        {shipment?.cliente?.CDPESSOA})
                      </td>
                      <td>{shipment?.DTABERTURA}</td>
                      <td>
                        <Button
                          size="sm"
                          color="primary"
                          onClick={() =>
                            handleShipmentDetails(shipment?.IDPROCESSO)
                          }
                        >
                          Detalhes
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CardBody>
        </div>
        <CardFooter></CardFooter>
      </Card>
    </div>
  );
};

export default Shipments;
