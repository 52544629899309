import api from "./api";
class Integration {
  #url = process.env.REACT_APP_API_URL + "/integrations";

  #get_uri = "/details?integration_id=";
  #post_uri = "/create";
  #patch_uri = "/update";
  #delete_uri = "/delete?integration_id=";

  async getAll() {
    const resp = await api.get(this.#url);
    return resp.data;
  }
  async get(id) {
    const resp = await api.get(this.#url + this.#get_uri + id);
    return resp.data;
  }
  async create(integration) {
    const resp = await api.post(this.#url + this.#post_uri, {
      name: integration.name,
      api_client_id: "teste",
      api_client_secret: "teste",
    });
    return resp.data;
  }
  async update({ name, id }) {
    const resp = await api.patch(this.#url + this.#patch_uri, {
      integration_id: id,
      integration_name: name,
    });
    return resp.data;
  }
  async remove(id) {
    const resp = await api.delete(this.#url + this.#delete_uri + id);
    return resp.data;
  }
}

export default new Integration();
