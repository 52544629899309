import "./index.css";

import { useState } from "react";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Input, Label } from "reactstrap";

import brokerRequests from "requests/brokerRequests";
import customerRequest from "requests/customerRequest";

const Customers = () => {
  const [urlParameters] = useSearchParams();

  const [brokerCustomer, setBrokerCustomer] = useState({});
  const [customerSearch, setCustomerSearch] = useState("");

  const handleSearchCustomer = (e) => {
    e.preventDefault();

    const credentialId = urlParameters.get("access_id");

    toast
      .promise(brokerRequests.findCustomers(credentialId, customerSearch), {
        loading: "Pesquisando cliente",
        success: "Cliente",
        erro: "Não foi possível encontrar o cliente",
      })
      .then((response) => setBrokerCustomer(response));
  };

  const handleSelectService = (service) => {
    if (isServiceSelected(service)) {
      removeServiceToCustomer(service);
    } else {
      addServiceToCustomer(service);
    }
  };

  const addServiceToCustomer = (newService) => {
    const credential_id = urlParameters.get("access_id");

    const requestBody = {
      broker_customer_id: brokerCustomer?.IDPESSOA,
      broker_service_id: newService?.IDSERVICO,
      credential_id,
    };

    toast
      .promise(customerRequest.addServiceToCustomer(requestBody), {
        loading: "Adicionando serviço",
        success: "Serviço adicionado com sucesso",
        error: "Não foi possível adicionar o serviço",
      })
      .then((response) => {
        brokerCustomer?.integration_services?.push(response);
        setBrokerCustomer({ ...brokerCustomer });
      });
  };

  const removeServiceToCustomer = (removedService) => {
    const credential_id = urlParameters.get("access_id");

    const requestBody = {
      broker_customer_id: brokerCustomer?.IDPESSOA,
      broker_service_id: removedService?.IDSERVICO,
      credential_id,
    };

    toast
      .promise(customerRequest.removeServiceToCustomer(requestBody), {
        loading: "Removendo serviço",
        success: "Serviço removido!",
        error: "Não foi possível remover o serviço",
      })
      .then((response) => {
        brokerCustomer.integration_services = response;
        setBrokerCustomer({ ...brokerCustomer });
      });
  };

  const isServiceSelected = (brokerService) => {
    return (
      brokerCustomer?.integration_services?.filter(
        (integrationService) =>
          brokerService?.IDSERVICO ===
          Number(integrationService?.broker_service_id)
      ).length > 0
    );
  };

  const addAllServicesToCustomer = () => {
    const credential_id = urlParameters.get("access_id");

    const requestBody = {
      broker_customer_id: brokerCustomer?.IDPESSOA,
      credential_id,
    };

    toast
      .promise(customerRequest.addAllServicesToCustomer(requestBody), {
        loading: "Adicionando todos os serviços ao cliente",
        success: "Serviços adicionados com sucesso",
        error: "Não foi possível adicionar todos os serviços ao cliente",
      })
      .then((response) => {
        brokerCustomer.integration_services = response;
        setBrokerCustomer({ ...brokerCustomer });
      });
  };

  const removeAllServicesToCustomer = () => {
    const credential_id = urlParameters.get("access_id");

    const requestBody = {
      broker_customer_id: brokerCustomer?.IDPESSOA,
      credential_id,
    };

    toast
      .promise(customerRequest.removeAllServicesToCustomer(requestBody), {
        loading: "Removendo todos os serviços do cliente",
        success: "Sucesso",
        error: "Não foi possível remover todos os servicos do cliente",
      })
      .then((response) => {
        brokerCustomer.integration_services = response;
        setBrokerCustomer({ ...brokerCustomer });
      });
  };

  const allServicesSelected = () => {
    const brokerTotalServices = brokerCustomer?.services?.length;
    const totalSelectedServices = brokerCustomer?.integration_services?.length;

    return brokerTotalServices === totalSelectedServices;
  };

  return (
    <div className="customer-container">
      <form
        className="customer-search"
        onSubmit={(e) => handleSearchCustomer(e)}
      >
        <Input
          placeholder="Pesquisar por CNPJ ou nome"
          onChange={(e) => setCustomerSearch(e.target.value)}
        ></Input>
        <Button color="primary" type="submit">
          Pesquisar
        </Button>
      </form>
      <Card style={{ height: "100%" }}>
        <CardHeader>
          Listagem de serviços{" "}
          {Object.values(brokerCustomer)?.length > 0 &&
            `Cliente: ${brokerCustomer?.NMPESSOA}`}{" "}
          {brokerCustomer?.CNPJ}
        </CardHeader>
        <CardBody className="services-container">
          {Object.values(brokerCustomer).length > 0 && (
            <>
              {allServicesSelected() ? (
                <Button
                  color="danger"
                  size="sm"
                  className="my-3"
                  onClick={removeAllServicesToCustomer}
                >
                  Remover todos os serviços
                </Button>
              ) : (
                <Button
                  color="primary"
                  size="sm"
                  className="my-3"
                  onClick={addAllServicesToCustomer}
                >
                  Adicionar todos os serviços
                </Button>
              )}
              <div className="services-list">
                <p>
                  {brokerCustomer?.NMPESSOA} {brokerCustomer?.CNPJ}
                </p>
                <ol>
                  {brokerCustomer?.services?.map((service) => (
                    <li key={service?.CDSERVICO}>
                      <Input
                        id={service?.IDSERVICO}
                        type="checkbox"
                        onChange={() => handleSelectService(service)}
                        checked={isServiceSelected(service)}
                      />
                      <label htmlFor={service?.IDSERVICO}>
                        <p>
                          {service?.NMSERVICO} - ({service?.CDSERVICO})
                        </p>
                      </label>
                    </li>
                  ))}
                </ol>
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Customers;
