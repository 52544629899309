import React, { Suspense } from "react";
import { useLocation } from "react-router-dom";
//--------------------------------------------------------
import Integrations from "pages/Integrations";
//--------------------------------------------------------
import Credentials from "pages/Integrations/Credentials";
import CreateCredentials from "pages/Integrations/Credentials/Create";
import EditCredentials from "pages/Integrations/Credentials/Edit";
//--------------------------------------------------------
import TransvoyantShipments from "pages/TransvoyantShipments";
import TransvoyantShipmentDetails from "pages/TransvoyantShipments/Details";
//--------------------------------------------------------
import Shipments from "pages/Shipments";
import ShipmentDetails from "pages/Shipments/Details";
//--------------------------------------------------------
import Events from "pages/Events";

import Home from "pages/Home";
import NotFound from "pages/NotFound";
import AddCustomers from "pages/Customers/Add";
import ListCustomers from "pages/Customers";
import CustomerDetails from "pages/Customers/Details";
import Expenses from "pages/Integrations/Credentials/Expenses";

const routes = [
  {
    name: "GREENWICH INTERNATIONAL", // nome do app - titulo da home
    path: "/",
    element: <Home />,
    layout: "default",
    head: true,
  },
  {
    name: "Integrações",
    path: "/integrations",
    element: <Integrations />,
    layout: "default",
    isVisible: true,
    head: true,
  },
  {
    name: "Integração - Lista de Acessos",
    path: "integrations/access",
    element: <Credentials />,
    layout: "default",
  },
  {
    name: "Integração - Criar Acesso",
    path: "integrations/access/create",
    element: <CreateCredentials />,
    layout: "default",
  },
  {
    name: "Integração - Editar Acesso",
    path: "integrations/access/edit",
    element: <EditCredentials />,
    layout: "default",
  },
  {
    name: "Eventos",
    path: "integrations/events",
    element: <Events />,
    layout: "default",
    isVisible: 0,
  },
  {
    name: "Adicionar Cliente",
    path: "integrations/customers/add",
    element: <AddCustomers />,
    layout: "default",
    isVisible: 0,
  },
  {
    name: "Clientes",
    path: "integrations/customers",
    element: <ListCustomers />,
    layout: "default",
    isVisible: false,
  },
  {
    name: "Despesas",
    path: "integrations/expenses",
    element: <Expenses />,
    layout: "default",
    isVisible: false,
  },
  {
    name: "Detalhes do Cliente",
    path: "integrations/customers/details",
    element: <CustomerDetails />,
    layout: "default",
    isVisible: false,
  },
  {
    name: "Processos Transvoyant",
    path: "/transvoyant-shipments",
    element: <TransvoyantShipments />,
    layout: "default",
    isVisible: false,
  },
  {
    name: "Not Found",
    path: "/404",
    element: <NotFound />,
    layout: "default",
    head: true,
  },
  {
    name: "Detalhes do Processo",
    path: "/transvoyant-shipment/details",
    element: <TransvoyantShipmentDetails />,
    layout: "default",
  },
  {
    name: "Processos",
    path: "/broker/shipments",
    element: <Shipments />,
    layout: "default",
    head: true,
    isVisible: true,
    head: true
  },
  {
    name: "Detalhes do processo",
    path: "broker/shipment/details",
    element: <ShipmentDetails />,
    layout: "default",
    isVisible: false,
  },
];

export { routes };

export function Get_Route() {
  const _location = useLocation();
  const _route = routes.find((route) => {
    return route.head
      ? _location.pathname == route.path
      : _location.pathname == "/" + route.path;
  });
  return _route ?? "Not Found";
}
