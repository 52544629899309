import api from "./api";

class CustomerRequest {
  async addServiceToCustomer(requestBody) {
    const res = await api.post(
      "/integrations/customers/services/add",
      requestBody
    );
    return res.data;
  }

  async removeServiceToCustomer(requestBody) {
    const res = await api.delete("/integrations/customers/services/remove", {
      params: requestBody,
    });
    return res.data;
  }

  async addAllServicesToCustomer(requestBody) {
    const res = await api.post(
      "/integrations/customers/services/add-all",
      requestBody
    );
    return res.data;
  }

  async removeAllServicesToCustomer(requestBody) {
    const res = await api.delete(
      "/integrations/customers/services/remove-all",
      { params: requestBody }
    );
    return res.data;
  }

  async getCustomersByCredential(credentialId) {
    const res = await api.get(
      `/integrations/customers/list?credential_id=${credentialId}`
    );
    return res.data;
  }

  async getCustomerDetails(customerId) {
    const res = await api.get(
      `/integrations/customers/details?customer_id=${customerId}`
    );
    return res.data;
  }

  async inactivateCustomer(customer_id) {
    const res = await api.patch("/integrations/customers/inactivate", {
      customer_id,
    });
    return res?.data;
  }
}

export default new CustomerRequest();
