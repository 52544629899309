import ReturnButton from "components/ReturnButton";
import { Get_Route } from "routes";
import Logo from "./Logo";
import { useLocation } from "react-router-dom";

import "../assets/styles/app.css";

const Header = () => {
  const subRoute = true;
  const location = useLocation();

  return (
    <div className={`header-container`}>
      <div className="left">
        {!Get_Route().head ? <ReturnButton className="return-button" /> : null}
        <h1>{Get_Route().name}</h1>
      </div>
    </div>
  );
};

export default Header;
