import { Spinner } from "reactstrap";
import "assets/styles/colors.css";

const Loading = ({ message = "Carregando..." }) => {
  return (
    <span
      style={{ display: "flex", alignItems: "center", color: "var(--COLOR_1)" }}
    >
      <Spinner>{null}</Spinner>
      <p
        style={{
          fontStyle: "italic",
          fontSize: "1rem",
          margin: " 0 0 0 1rem ",
          color: "black",
        }}
      >
        {message}
      </p>
    </span>
  );
};

export default Loading;
