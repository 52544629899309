import "./index.css";

import toast from "react-hot-toast";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Table,
} from "reactstrap";

import transvoyantRequest from "../../requests/transvoyantRequest";
import dateHelper from "helpers/dateHelper";

const TransvoyantShipments = () => {
  const [shipments, setShipments] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    toast
      .promise(transvoyantRequest.getTransvoyantShipments(), {
        loading: "Carregando processos...",
        success: "Sucesso",
        error: "Não foi possível processar os dados",
      })
      .then((res) => setShipments(res.data));
  }, []);

  const handleShipmentDetails = (shipmentId) => {
    navigate({
      pathname: "/transvoyant-shipment/details",
      search: `?shipment_id=${shipmentId}`,
    });
  };

  return (
    <div className="card-container">
      <Card>
        <CardHeader>Processos integrados</CardHeader>
        <CardBody>
          <Table hover responsive striped size="sm">
            <thead>
              <tr style={{ textTransform: "uppercase" }}>
                <th>#ID Processo Broker</th>
                <th>Referência do Processo</th>
                <th>Modal</th>
                <th>Carrier</th>
                <th>POL (PORT OF LANDING)</th>
                <th>POD (PORT OF DISCHARGE)</th>
                <th>HOUSEAWB</th>
                <th>Data de Envio</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {shipments?.map((shipment) => (
                <tr key={shipment?.id}>
                  <td>{shipment?.broker_shipment_id}</td>
                  <td>{shipment?.customerReferenceNumber}</td>
                  <td>{shipment?.modal}</td>
                  <td>{shipment?.carrier}</td>
                  <td>{shipment?.pol}</td>
                  <td>{shipment?.pod}</td>
                  <td>{shipment?.houseAwb}</td>
                  <td>
                    {shipment?.created_at &&
                      dateHelper
                        .formatDateWithHours(shipment?.created_at)
                        ?.toString()}
                  </td>
                  <td>
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => handleShipmentDetails(shipment?.id)}
                    >
                      Detalhes
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
        <CardFooter></CardFooter>
      </Card>
    </div>
  );
};

export default TransvoyantShipments;
