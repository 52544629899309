import "./index.css";

import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Table,
  Nav,
  NavItem,
} from "reactstrap";
import { useSearchParams } from "react-router-dom";

import dateHelper from "helpers/dateHelper";
import brokerRequests from "requests/brokerRequests";

const ShipmentDetails = () => {
  const [urlParameters] = useSearchParams();
  const [shipment, setShipment] = useState({});

  const TABS = {
    ATTRIBUTES: "ATTRIBUTES",
    EVENTS: "EVENTS",
    COSTS: "COSTS",
  };

  const [activeTab, setActiveTab] = useState(TABS.ATTRIBUTES);

  useEffect(() => {
    const shipmentId = urlParameters.get("shipment_id");

    toast
      .promise(brokerRequests.getShipment(shipmentId), {
        loading: "Carregando processo",
        success: "Sucesso",
        error: "Não foi possível carregar o processo",
      })
      .then((data) => {
        setShipment(data);
      });
  }, []);

  const getTabProps = (tab) => {
    return {
      className: isTabActive(tab) ? "active-tab" : "",
      onClick: (e) => setActiveTab(tab),
    };
  };

  const isTabActive = (tab) => {
    return activeTab === tab;
  };

  const currencyFormat = (value, currency) =>
    new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: currency,
    }).format(value);

  return (
    <div className="card-container">
      <div className="details-container full-height">
        <Card>
          <CardHeader>{shipment?.NRPROCESSO}</CardHeader>
          <CardBody>
            <ListGroup flush>
              <ListGroupItem>
                <div className="shipment-data">
                  <b>ID Broker</b>
                  <p>{shipment?.IDPROCESSO}</p>
                </div>

                <div className="shipment-data">
                  <b>Referência Auxiliar</b>
                  <p>{shipment?.NRREFAUXILIAR}</p>
                </div>

                <div className="shipment-data">
                  <b>Modal</b>
                  <p>{shipment?.viatransporte?.NMVIATRANSPORTE}</p>
                </div>

                <div className="shipment-data">
                  <b>Abertura</b>
                  <p>{shipment?.DTABERTURA}</p>
                </div>
              </ListGroupItem>

              <ListGroupItem>
                <div className="shipment-data">
                  <b>House</b>
                  <p>{shipment?.NRCONHECIMENTO}</p>
                </div>

                <div className="shipment-data">
                  <b>Master</b>
                  <p>{shipment?.NRCONHECMASTER}</p>
                </div>

                <div className="shipment-data">
                  <b>Pol (Port of Landing)</b>
                  <p>{shipment?.local_embarque?.NMTERMINALCARGA}</p>
                </div>

                <div className="shipment-data">
                  <b>Pod (Port of Discharge)</b>
                  <p>{shipment?.local_desembarque?.NMTERMINALCARGA}</p>
                </div>
              </ListGroupItem>

              <ListGroupItem>
                {/* traducao */}
                <div className="shipment-data">
                  {/* <b>Portador</b> */}
                  <b>Carrier</b>
                  <p>{shipment?.transporte_internacional?.NMPESSOA}</p>
                </div>

                <div className="shipment-data">
                  <b>Container</b>
                  <p>{shipment?.container?.NRCONTAINER}</p>
                </div>

                <div className="shipment-data">
                  <b>Container Type</b>
                  <p>{shipment?.container?.tipo_container?.NMTIPOCONTAINER}</p>
                </div>

                <div className="shipment-data">
                  <b>Embarcação</b>
                  <p>{shipment?.embarcacao?.NMEMBARCACAO}</p>
                </div>

                <div className="shipment-data">
                  <b>Viagem</b>
                  <p>{shipment?.NRNUMEROVIAGEM}</p>
                </div>
              </ListGroupItem>
              <ListGroupItem>
                <div className="shipment-data">
                  <b>Código do Cliente</b>
                  <p>{shipment?.cliente?.CDPESSOA}</p>
                </div>

                <div className="shipment-data">
                  <b>Nome do Cliente</b>
                  <p>{shipment?.cliente?.APPESSOA}</p>
                </div>

                <div className="shipment-data">
                  <b>CPF/CNPJ</b>
                  <p>
                    {shipment?.cliente?.CNPJ
                      ? shipment?.cliente?.CNPJ
                      : shipment?.cliente?.CPF}
                  </p>
                </div>
              </ListGroupItem>
            </ListGroup>
            {/*shipmentHistory?.length > 0 && 
                                <ListGroup>
                                    <ListGroupItemHeading>Histórico de envio</ListGroupItemHeading>
                                        {shipmentHistory?.map((history, index) => (
                                            <div className="history-container" key={index}>
                                                {history.map((shipment, index) => (
                                                    <p key={index}>
                                                        {shipment?.key === 'created_at' || shipment?.key === 'updated_at' 
                                                            ? `${shipment?.key}: ${dateHelper.formatDateWithHours(shipment?.value).toString()}`
                                                            : `${shipment?.key}: ${shipment?.value}`
                                                        }
                                                    </p>
                                                ))}
                                            </div>    
                                        ))}
                                </ListGroup>
                            */}
            <h3>Histórico de Integração</h3>
            <Nav tabs>
              <NavItem {...getTabProps(TABS.ATTRIBUTES)}>Atributos</NavItem>
              <NavItem {...getTabProps(TABS.EVENTS)}>Eventos</NavItem>
              <NavItem {...getTabProps(TABS.COSTS)}>Custos</NavItem>
            </Nav>
            {isTabActive(TABS.ATTRIBUTES) && (
              <Table hover responsive striped size="sm">
                {/* traducao */}
                <thead>
                  <tr>
                    <th>Data</th>
                    {/* <th>Portador</th>*/}
                    <th>Carrier</th>
                    {/* <th>Referência</th>*/}
                    <th>Reference</th>
                    <th>Master</th>
                    <th>House</th>
                    <th>POD</th>
                    <th>POL</th>
                  </tr>
                </thead>
                <tbody>
                  {shipment?.atributos_integrados?.map((data) => (
                    <tr>
                      <td>{data.created_at}</td>
                      <td>{data.carrier}</td>
                      <td>{data.customerReferenceNumber}</td>
                      <td>{data.mawb}</td>
                      <td>{data.houseAwb}</td>
                      <td>{data.pod}</td>
                      <td>{data.pol}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

            {isTabActive(TABS.EVENTS) && (
              <Table hover responsive striped size="sm">
                <thead>
                  <tr>
                    <th>Data da Integração</th>
                    <th>Código</th>
                    <th>Descrição</th>
                    <th>Data do Evento</th>
                  </tr>
                </thead>
                <tbody>
                  {shipment?.eventos_integrados?.map((data) => (
                    <tr>
                      <td>{data.created_at}</td>
                      <td>{data.code}</td>
                      <td>{data.description}</td>
                      <td>{data.event_date}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {/* traducao */}
            {isTabActive(TABS.COSTS) && (
              <Table hover responsive striped size="sm">
                <thead>
                  <tr>
                    <th>Data da Integração</th>
                    {/* <th>Chave do Envio</th> */}
                    <th>Shipment Key</th>
                    {/* <th>Atual</th> */}
                    <th>Currency</th>
                    {/* <th>Custo Padrão</th> */}
                    <th>Standard Cost</th>
                    {/* <th>Custo Adicional</th> */}
                    <th>Additional Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {shipment?.custos_integrados?.map((data) => (
                    <tr>
                      <td>{data.created_at}</td>
                      <td>{data.shipmentKey}</td>
                      <td>{data.currency}</td>
                      <td>
                        {currencyFormat(data.standardCosts, data.currency)}
                      </td>
                      <td>
                        {currencyFormat(data.additionalCosts, data.currency)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>

        <Card style={{ overflow: "hidden" }}>
          <CardHeader>Follow Up</CardHeader>
          <CardBody>
            <Table hover responsive striped size="sm">
              <thead>
                <tr>
                  <th>ORD.</th>
                  <th>EVENTO</th>
                  <th>DATA</th>
                </tr>
              </thead>
              <tbody>
                {shipment?.followup?.map((followup) => (
                  <tr>
                    <td>{followup.NRORDEM}</td>
                    <td>
                      {followup.evento?.NMEVENTO} ({followup.IDEVENTO})
                    </td>
                    <td>{followup.DTREALIZACAO}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default ShipmentDetails;
