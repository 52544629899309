import api from "./api";

class TransvoyantRequest {
    async getTransvoyantShipments(){
        const res = await api.get("/transvoyant/shipments");
        return res.data;
    }

    async getTransvoyantShipmentDetails(transvoyantShipmentId) {
        const apiUrl = api.setUrlEndpoint("/transvoyant/shipments/details");
        apiUrl.searchParams.append('transvoyant_shipment_id', transvoyantShipmentId);

        const res = await api.get(apiUrl.toString());
        return res.data;
    }
}

export default new TransvoyantRequest();