import api from "./api";

class BrokerRequests {
  async findShipments(q, page = 1, per_page = 50) {
    const res = await api.get(
      `/broker/shipments?search=${encodeURI(
        q
      )}&page=${page}&per_page=${per_page}`
    );
    return res.data;
  }

  async getShipment(id) {
    const res = await api.get(`/broker/shipments/details?shipment_id=${id}`);
    return res.data;
  }

  async getServices() {
    const res = await api.get("/broker/services");
    return res.data;
  }

    async getExpenses() {
        const res = await api.get("/broker/expenses");
        return res.data;
    }

  async findCustomers(credentialId, customerSearch) {
    const res = await api.get(
      `/broker/customers?credential_id=${credentialId}&search=${customerSearch}`
    );
    return res.data;
  }
}

export default new BrokerRequests();
