import "./index.css";

import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
} from "reactstrap";
import { useSearchParams } from "react-router-dom";

import dateHelper from "helpers/dateHelper";
import transvoyantRequest from "requests/transvoyantRequest";

const TransvoyantShipmentDetails = () => {
  const [urlParameters] = useSearchParams();
  const [shipment, setShipment] = useState({});
  const [customer, setCustomer] = useState({});
  const [shipmentHistory, setShipmentHistory] = useState([]);
  const [shipmentEvents, setShipmentEvents] = useState([]);

  useEffect(() => {
    const shipmentId = urlParameters.get("shipment_id");

    toast
      .promise(transvoyantRequest.getTransvoyantShipmentDetails(shipmentId), {
        loading: "Carregando processo",
        success: "Processo carregado",
        error: "Não foi possível carregar o processo",
      })
      .then((res) => {
        setShipment(res);
        setShipmentEvents(res.transvoyant_events);
        setCustomer(res?.customer);
        const shipmentHistory = getShipmentHistoryNonEmptyValues(
          res.integration_history
        );
        setShipmentHistory(shipmentHistory);
      });
  }, []);

  const getShipmentHistoryNonEmptyValues = (shipmentHistory) => {
    return shipmentHistory.map((shipment) => {
      const historyWithoutEmptyValues = [];

      const shipmentKeys = Object.keys(shipment);
      shipmentKeys.forEach((key) => {
        if (shipment[key]) {
          historyWithoutEmptyValues.push({ key, value: shipment[key] });
        }
      });

      return historyWithoutEmptyValues;
    });
  };

  const getShipmentBrokerEventHour = (eventId) => {
    const brokerEvent = shipment?.broker_processo?.followup?.filter(
      (event) => event?.IDEVENTO === eventId
    );

    if (brokerEvent.length > 0) {
      return brokerEvent[0]?.DTREALIZACAO;
    }

    return "";
  };

  return (
    <div className="card-container">
      <div className="details-container">
        <Card>
          <CardHeader>
            Detalhes do processo #{shipment?.id} -{" "}
            {shipment?.customerReferenceNumber}
          </CardHeader>
          <CardBody>
            <ListGroup flush>
              <ListGroupItem>
                <div className="shipment-data">
                  <b>ID Broker</b>
                  <p>{shipment?.broker_shipment_id}</p>
                </div>
                <div className="shipment-data">
                  <b>Modal</b>
                  <p>{shipment?.modal}</p>
                </div>
                {/* REF. Cliente */}
                {/*  */}
                <div className="shipment-data">
                  <b>Customer reference number</b>
                  <p>{shipment?.customerReferenceNumber}</p>
                </div>

                <div className="shipment-data">
                  <b>Data de criação</b>
                  <p>
                    {shipment?.created_at &&
                      dateHelper
                        .formatDateWithHours(shipment?.created_at)
                        .toString()}
                  </p>
                </div>
              </ListGroupItem>

              <ListGroupItem>
                {shipment?.houseAwb && (
                  <div className="shipment-data">
                    <b>House Awb</b>
                    <p>{shipment?.houseAwb}</p>
                  </div>
                )}

                <div className="shipment-data">
                  <b>Mawb</b>
                  <p>{shipment?.mawb}</p>
                </div>

                <div className="shipment-data">
                  <b>Pol (Port of Landing)</b>
                  <p>{shipment?.pol}</p>
                </div>

                <div className="shipment-data">
                  <b>Pod (Port of Discharge)</b>
                  <p>{shipment?.pod}</p>
                </div>
              </ListGroupItem>

              <ListGroupItem>
                <div className="shipment-data">
                  <b>BOL</b>
                  <p>{shipment?.bol}</p>
                </div>

                <div className="shipment-data">
                  <b>Carrier</b>
                  <p>{shipment?.carrier}</p>
                </div>

                <div className="shipment-data">
                  <b>Carrier Scac</b>
                  <p>{shipment?.carrierScac}</p>
                </div>

                <div className="shipment-data">
                  <b>Container</b>
                  <p>{shipment?.container}</p>
                </div>

                <div className="shipment-data">
                  <b>Container Type</b>
                  <p>{shipment?.containerType}</p>
                </div>

                <div className="shipment-data">
                  <b>Vessel name</b>
                  <p>{shipment?.vesselName}</p>
                </div>

                <div className="shipment-data">
                  <b>Voyage</b>
                  <p>{shipment?.voyage}</p>
                </div>
              </ListGroupItem>
              <ListGroupItem>
                <div className="shipment-data">
                  <b>Cod. Cliente</b>
                  <p>{shipment?.cliente?.broker_customer?.CDPESSOA}</p>
                </div>

                <div className="shipment-data">
                  <b>Nome Cliente</b>
                  <p>{shipment?.customer?.broker_customer?.APPESSOA}</p>
                </div>

                <div className="shipment-data">
                  <b>CPF/CNPJ</b>
                  <p>
                    {shipment?.customer?.broker_customer?.CNPJ
                      ? shipment?.customer?.broker_customer?.CNPJ
                      : shipment?.customer?.broker_customer?.CPF}
                  </p>
                </div>
              </ListGroupItem>
            </ListGroup>
            {shipmentHistory?.length > 0 && (
              <ListGroup>
                <ListGroupItemHeading>Histórico de envio</ListGroupItemHeading>
                {shipmentHistory?.map((history, index) => (
                  <div className="history-container" key={index}>
                    {history.map((shipment, index) => (
                      <p key={index}>
                        {shipment?.key === "created_at" ||
                        shipment?.key === "updated_at"
                          ? `${shipment?.key}: ${dateHelper
                              .formatDateWithHours(shipment?.value)
                              .toString()}`
                          : `${shipment?.key}: ${shipment?.value}`}
                      </p>
                    ))}
                  </div>
                ))}
              </ListGroup>
            )}
          </CardBody>
        </Card>

        <Card>
          <CardHeader>Follow up de Eventos</CardHeader>
          <CardBody>
            {shipmentEvents?.map((event) => (
              <div className="event-container" key={event?.id}>
                <p>
                  <b>Nome do evento Transvoyant:</b>{" "}
                  {event?.integration_event?.event_code}
                </p>
                <p>
                  <b>Descrição do evento Transvoyant:</b>{" "}
                  {event?.integration_event?.event_name}
                </p>
                <p>
                  <b>Evento broker:</b> {event?.broker_event?.CDEVENTO}{" "}
                  {event?.broker_event?.NMEVENTO}
                </p>
                <p>
                  <b>Data da realização do evento broker: </b>
                  {getShipmentBrokerEventHour(event?.broker_event?.IDEVENTO)}
                </p>
                <p>
                  <b>Horário do envio: </b>{" "}
                  {event?.created_at &&
                    dateHelper
                      ?.formatDateWithHours(event?.created_at)
                      ?.toString()}
                </p>
              </div>
            ))}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default TransvoyantShipmentDetails;
