import Loading from "components/Loading";
import "./index.css";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  Label,
} from "reactstrap";

import brokerRequests from "requests/brokerRequests";
import customerRequest from "requests/customerRequest";

const CustomerDetails = () => {
  const [urlParameters] = useSearchParams();
  const [loading, setLoading] = useState(true);

  const [customer, setCustomer] = useState({});

  useEffect(() => {
    const customerId = urlParameters.get("customer_id");
    customerRequest.getCustomerDetails(customerId).then((response) => {
      setLoading(false);
      setCustomer(response);
    });
  }, []);

  const handleSelectService = (service) => {
    if (isServiceSelected(service)) {
      removeServiceToCustomer(service);
    } else {
      addServiceToCustomer(service);
    }
  };

  const addServiceToCustomer = (newService) => {
    const requestBody = {
      broker_customer_id: customer?.broker_customer_id,
      broker_service_id: newService?.IDSERVICO,
      credential_id: customer?.credential_id,
    };

    toast
      .promise(customerRequest.addServiceToCustomer(requestBody), {
        loading: "Adicionando serviço",
        success: "Sucesso",
        error: "Não foi possível adicionar o serviço",
      })
      .then((response) => {
        customer?.services?.push(response);
        setCustomer({ ...customer });
      });
  };

  const removeServiceToCustomer = (removedService) => {
    const requestBody = {
      broker_customer_id: customer?.broker_customer_id,
      broker_service_id: removedService?.IDSERVICO,
      credential_id: customer?.credential_id,
    };

    toast
      .promise(customerRequest.removeServiceToCustomer(requestBody), {
        loading: "Removendo serviço",
        success: "Sucesso",
        error: "Não foi possível remover o serviço",
      })
      .then((response) => {
        customer.services = response;
        setCustomer({ ...customer });
      });
  };

  const isServiceSelected = (brokerService) => {
    return (
      customer?.services?.filter(
        (integrationService) =>
          brokerService?.IDSERVICO ===
          Number(integrationService?.broker_service_id)
      ).length > 0
    );
  };

  const addAllServicesToCustomer = () => {
    const requestBody = {
      broker_customer_id: customer?.broker_customer_id,
      credential_id: customer?.credential_id,
    };

    toast
      .promise(customerRequest.addAllServicesToCustomer(requestBody), {
        loading: "Adicionando todos os serviços ao cliente",
        success: "Sucesso",
        error: "Não foi possível adicionar todos os serviços ao cliente",
      })
      .then((response) => {
        customer.services = response;
        setCustomer({ ...customer });
      });
  };

  const removeAllServicesToCustomer = () => {
    const requestBody = {
      broker_customer_id: customer?.broker_customer_id,
      credential_id: customer?.credential_id,
    };

    toast
      .promise(customerRequest.removeAllServicesToCustomer(requestBody), {
        loading: "Removendo todos os serviços do cliente",
        success: "Sucesso",
        error: "Não foi possível remover todos os servicos do cliente",
      })
      .then((response) => {
        customer.services = response;
        setCustomer({ ...customer });
      });
  };

  const allServicesSelected = () => {
    const brokerTotalServices = customer?.all_services?.length;
    const totalSelectedServices = customer?.services?.length;

    return brokerTotalServices === totalSelectedServices;
  };

  return (
    <div
      style={{ height: "calc(100% - 1rem)", overflowY: "auto" }}
      className="customer-container"
    >
      <Card >
        {loading ? (
          <CardBody className="loading-container">
            <Loading message={"Buscando Serviços do Cliente"} />
          </CardBody>
        ) : (
          <>
            <CardHeader>
              {Object.values(customer)?.length > 0 &&
                `${customer?.broker_customer?.NMPESSOA}`}{" "}
              {customer?.broker_customer?.CNPJ}
            </CardHeader>
            <CardBody style={{ height: "calc(100% - 1rem)", overflowY: "auto" }} className="services-container">
              {Object.values(customer).length > 0 && (
                <>
                  {allServicesSelected() ? (
                    <Button
                      color="danger"
                      size="sm"
                      className="my-3"
                      onClick={removeAllServicesToCustomer}
                    >
                      Remover todos os serviços
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      size="sm"
                      className="my-3"
                      onClick={addAllServicesToCustomer}
                    >
                      Adicionar todos os serviços
                    </Button>
                  )}
                  <div className="services-list">
                    <p>
                      {customer?.broker_customer?.NMPESSOA} -{" "}
                      {customer?.broker_customer?.CNPJ}
                    </p>
                    <ol>
                      {customer?.all_services?.map((service) => (
                        <li key={service?.CDSERVICO}>
                          <Input
                            id={service?.IDSERVICO}
                            type="checkbox"
                            onChange={() => handleSelectService(service)}
                            checked={isServiceSelected(service)}
                          />
                          <label htmlFor={service?.IDSERVICO}>
                            <p>
                              {service?.NMSERVICO} - ({service?.CDSERVICO})
                            </p>
                          </label>
                        </li>
                      ))}
                    </ol>
                  </div>
                </>
              )}
            </CardBody>
            <CardFooter></CardFooter>
          </>
        )}
      </Card>
    </div>
  );
};

export default CustomerDetails;
