import "./index.css";

import toast from "react-hot-toast";
import { useState } from "react";
import { Button, Card, CardBody, CardHeader, FormGroup, Input, Label, Form , Col, Row } from "reactstrap";

import authRequests from "requests/authRequests";
import Logo from "components/Logo";

const Login = ({onLoginSuccess = () => {}}) => {


    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const handleLogin = (e) => {
        e.preventDefault();

        toast.promise(authRequests.login(email , password), {
			loading: "Realizando Login",
			success: "Logado com sucesso",
            error: "Email ou senha inválido"
		}).then(res => {
            localStorage.setItem(process.env.REACT_APP_TOKEN_KEY , res.data.token);
            window.location.assign("/");
        });
    }

    return (
        <div className="login-container">         
            <Logo/>
            <Card>
                <CardHeader>Autenticação</CardHeader>
                <CardBody>
                    <Form onSubmit={event => handleLogin(event)}>
                        <FormGroup>
                            <Row className="mb-3">
                                <Col sm="12">
                                    <Label>Email</Label>
                                    <Input type="email" placeholder="Email" onChange={e => setEmail(e.target.value)}></Input>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col sm="12">
                                    <Label>Senha</Label>
                                    <Input type="password" placeholder="Senha" onChange={e => setPassword(e.target.value)}></Input>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col sm="4">
                                    <Button color="primary" type="submit">Autenticar</Button>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Form>
                </CardBody>
            </Card>
        </div>
    )
}

export default Login;