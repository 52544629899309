import api from "./api";

class Events {

  async get(id) {
    //TODO: Paginação
    const resp = await api.get('/integrations/events/list', {
        params: {
            integration_id: id,
            page: 1,
            per_page: 100
        }
    });

    return resp.data;
  }

  async getTransvoyantEventCodes() {
      const resp = await api.get("/integrations/events/transvoyant/event_codes");
      return resp.data;
  }

  async getBrokerEvents(eventName = "") {
      const resp = await api.get("/broker/events");
      return resp.data;
  }
  

  async updateBrokerEvent(requestBody) {
		const resp = await api.patch("/integrations/events/update-broker-event", requestBody);
		return resp.data;
  }

  async updateEventDescription(requestBody) {
    const resp = await api.patch("/integrations/events/update-event-description", requestBody);
    return resp.data;
  }

  async addInfoEvent(requestBody){
    const resp = await api.post("/integrations/events/info/add", requestBody);
    return resp.data;
  }

  async deleteInfoEvent(requestBody){
    const resp = await api.delete("/integrations/events/info/delete", {params: requestBody});
    return resp.data;
  }
  
}

export default new Events();
