import { useState } from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Col,
  Row,
  Card,
} from "reactstrap";

const ConfirmationModal = ({
  onApproval = () => {},
  onReproval = () => {},
  modal,
  title,
  toggle,
  children,
}) => {
  return (
    <Modal isOpen={modal} toggle={toggle} autoFocus>
      <ModalHeader>{title || "Confirmação: "}</ModalHeader>
      <ModalBody>{children || "Deseja prosseguir?"}</ModalBody>
      {/* enter para confirmar e esc para cancelar */}
      <ModalFooter>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onApproval();
            toggle();
          }}
        >
          <FormGroup row>
            <Col>
              <Button md={2} type="submit" color="danger" outline>
                Excluir
              </Button>
            </Col>
            <Col>
              <Button
                md={2}
                onClick={() => {
                  onReproval();
                  toggle();
                }}
                color="primary"
                outline
              >
                Cancelar
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </ModalFooter>
    </Modal>
  );
};
export default ConfirmationModal;
