const Button = (props) => {
  return (
    <button
      style={{
        cursor: "pointer",
        textTransform: "capitalize",
        borderRadius: props.rounded ? Number.MAX_SAFE_INTEGER + "px" : "15px",
        padding: "3px 20px",
        border: "none",
      }}
      {...props}
    >
      {props.children ?? props.content ?? "clique"}
    </button>
  );
};

export default Button;
