import { useNavigate } from "react-router-dom";

import logoDark from "../assets/logos/logo_dark.png";
import logoLight from "../assets/logos/logo_light.png";

import "../assets/styles/app.css";
const Logo = ({ theme = "light" }) => {
  const navigate = useNavigate();
  return theme == "dark" ? (
    <img
      style={{ cursor: "pointer" }}
      onClick={() => navigate("/")}
      src={logoDark}
      className="logo"
      alt="Greenwich International"
    />
  ) : (
    <img
      style={{ cursor: "pointer" }}
      onClick={() => navigate("/")}
      src={logoLight}
      className="logo"
      alt="Greenwich International"
    />
  );
};

export default Logo;
