import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import integrationRequest from "requests/integrationRequest";
import credentialsRequests from "requests/credentialsRequests";
import ConfirmationModal from "components/ConfirmationModal";
import ReturnButton from "components/ReturnButton";
import Button from "components/Button";
import toast from "react-hot-toast";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ButtonDropdown,
  UncontrolledButtonDropdown,
  Row,
  Spinner,
} from "reactstrap";

import "./style.css";
import Loading from "components/Loading";

const Credentials = () => {
  const [credentials, setCredentials] = useState([]);
  const [focusCredential, setFocusCredential] = useState(null);
  const [resp, setResp] = useState(null);
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [integration, setIntegration] = useState({});
  const [searching, setSearching] = useState(true);

  const location = useLocation();
  const { state } = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);

  useEffect(() => {
    credentialsRequests
      .listCredentialsByIntegrationID(query.get("integration_id"))
      .then((el) => {
        setCredentials(el);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setSearching(false);
      });
  }, []);

  useEffect(() => {
    setIntegration(state.integration);
  }, [credentials]);

  const timeHandle = (time = "") => {
    if (time == "") return null;
    const date = new Date(time);
    time = date.toLocaleDateString() + " • " + date.toLocaleTimeString();
    return time;
  };

  const editHandle = (credential) => {
    navigate("edit", {
      state: { credential, integration },
    });
  };
  const deleteHandle = () => {
    const credential = focusCredential;
    toast
      .promise(
        credentialsRequests.remove(credential.id, credential.integration.id),
        {
          loading: "Removendo acesso, por favor aguarde...",
          success: (el) => {
            return "Sucesso - Acesso removido!";
          },
          error: (err) =>
            "Não foi possível remover este acesso - " +
            err.response.data.error_message,
        }
      )
      .then((el) => {
        setCredentials(
          credentials.filter((el) => el.id !== focusCredential.id)
        );
      })
      .catch((err) => console.error(err));
  };

  const toggleConfirmationModal = () => {
    setConfirmationModal(!showConfirmationModal);
  };

  const customerDetailsHandle = (credential_id) => {
    navigate({
      pathname: "/integrations/customers",
      search: `?access_id=${credential_id}`,
    });
  };

  const expensesHandle = (credential_id) => {
    navigate({
      pathname: "/integrations/expenses",
      search: `?credential_id=${credential_id}`,
    });
  };

  return (
    <>
      <div className="credentials-container">
        <div className="card-title">
          {integration.name} • acessos
          <ReturnButton className="return-button" />
        </div>
        {credentials.length > 0 ? (
          <div className="table-container">
            <table>
              <thead
                style={{
                  position: "sticky",
                  top: "0",
                  background: "white",
                  zIndex: "100",
                }}
              >
                <tr>
                  <th>ID</th>
                  <th className="main-col">Nome</th>
                  <th>Criação </th>
                  <th>Última Atualização</th>
                  <th
                    colSpan="2"
                    style={{
                      textAlign: "center",
                      // position: "sticky",
                      // top: "0",
                    }}
                  >
                    Opções
                  </th>
                </tr>
              </thead>
              <tbody>
                {credentials.map((credential, index) => (
                  <tr key={index}>
                    <td>{credential.id}</td>
                    <td className="main-col">{credential.name}</td>
                    <td>{timeHandle(credential.integration?.created_at)}</td>
                    <td>{timeHandle(credential.integration?.updated_at)}</td>
                    <td className="bt-col">
                      <UncontrolledButtonDropdown>
                        <DropdownToggle caret>{null}</DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              editHandle(credential);
                            }}
                          >
                            Editar
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              customerDetailsHandle(credential?.id);
                            }}
                          >
                            Clientes
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setFocusCredential(credential);
                              toggleConfirmationModal();
                            }}
                          >
                            Remover
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              expensesHandle(credential?.id);
                            }}
                          >
                            Despesas
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : searching ? (
          <div className="nullable-table">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "3rem",
              }}
            >
              <Loading message={"Carregando acessos..."} />
            </div>
          </div>
        ) : (
          <div className="nullable-table">Nenhum acesso encontrado!</div>
        )}

        <div className="card-footer">
          <Button
            onClick={() =>
              navigate("create", {
                state: { integration },
              })
            }
            className="add-button"
          >
            Adicionar
          </Button>
        </div>
      </div>

      <ConfirmationModal
        toggle={toggleConfirmationModal}
        modal={showConfirmationModal}
        onApproval={deleteHandle}
        title={"Confirmação"}
      >
        {focusCredential?.name && (
          <p style={{ fontSize: "1rem", textAlign: "justify" }}>
            Tem certeza que deseja remover o acsso:{"  "}
            <b style={{ fontSize: "1rem" }}>{focusCredential.name}</b>? <br />
            Após a exclusão não será possível recuperar os dados de acesso.
          </p>
        )}
      </ConfirmationModal>
    </>
  );
};

export default Credentials;
